<template>
    <section class="pr-3">
        <div class="row mx-0 align-items-center mb-3">
            <div class="col-auto f-600 text-general f-18">
                Compras requeridas para el abastecimiento
            </div>
            <div class="btn-general f-14 px-4 ml-auto" @click="generarInformeNuevo">
                Generar nuevamente
            </div>
        </div>
        <div class="row mx-0 br-8 bg-white px-2 py-3 mb-3">
            <p class="col-12 text-general f-600">
                Datos de analisis
            </p>
            <div class="row mx-0">
                <div class="col-auto">
                    <div class="row mx-0 text-general align-items-center">
                        Cedis
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:300px">
                                Selecciona cuál es el Cedis del que quieres hacer el análisis, teniendo en cuenta sus productos a la venta. Si seleccionas "Familia de Cedis" serán tenidos en cuenta todos los productos de todos los demás Cedis que comparten almacenamiento con el seleccionado.
                            </div>
                            <i class="icon-help-circle-outline f-22 text-general" />
                        </el-tooltip>
                    </div>
                    <p class="text-general f-600 f-20">
                        {{ parametros.cedisSeleccionado }}
                        <template v-if="parametros.selectFamilia">
                            <el-popover ref="inventarioPopover" placement="bottom" class="mt-1" popper-class="p-0 br-12" trigger="click" width="350">
                                <div class="row mx-0 justify-center text-general mt-3 mb-3">
                                    <div v-if="parametros.cedisSeleccionado != parametros.cedisFamilia.padre" class="col-10 f-15 text-general f-500 mb-1">
                                        {{ parametros.cedisFamilia.padre }}
                                    </div>
                                    <div v-for="(data, index) in parametros.cedisFamilia.hijos" :key="index" class="col-10 f-15 text-general f-500 mb-1">
                                        <p>{{ data.nombre }}</p>
                                    </div>
                                </div>
                                <span slot="reference" class="ml-3 text-general-red f-14 cr-pointer">
                                    Ver familia
                                </span>
                            </el-popover>
                        </template>
                    </p>
                </div>
                <div class="col-auto">
                    <div class="row mx-0 text-general align-items-center">
                        Meses de rotación
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:300px">
                                Cantidad de meses sobre los cuales se hará el análisis de comportamiento de compra, definiendo cual es la rotación de los productos para tomar decisión de compra.
                            </div>
                            <i class="icon-help-circle-outline f-22 text-general" />
                        </el-tooltip>
                    </div>
                    <p class="text-general f-600 f-20">
                        {{ parametros.cantidadMeses }}
                    </p>
                </div>
                <div class="col-auto">
                    <div class="row mx-0 text-general align-items-center">
                        Días de compra
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:300px">
                                Cantidad de días que se espera se tenga abastecimiento para atender la demanda.
                            </div>
                            <i class="icon-help-circle-outline f-22 text-general" />
                        </el-tooltip>
                    </div>
                    <p class="text-general f-600 f-20">
                        {{ parametros.cantidadDias }}
                    </p>
                </div>
                <div class="col-auto">
                    <div class="row mx-0 text-general align-items-center">
                        Carrito de compras
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:300px">
                                Selecciona carrito si quieres que sean tenidos en cuenta los productos que actualmente están en intención de compra de los clientes, o no lo selecciones si quieres que en el análisis solo sean tenidos en cuenta los pedidos confirmados.
                            </div>
                            <i class="icon-help-circle-outline f-22 text-general" />
                        </el-tooltip>
                    </div>
                    <p class="text-general f-600 f-20">
                        {{ parametros.selectCarrito ? 'Si' : 'No' }}
                    </p>
                </div>
                <div class="col-auto">
                    <div class="row mx-0 text-general align-items-center">
                        Cantidad a ignorar
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:300px">
                                Selecciona carrito si quieres que sean tenidos en cuenta los productos que actualmente están en intención de compra de los clientes, o no lo selecciones si quieres que en el análisis solo sean tenidos en cuenta los pedidos confirmados.
                            </div>
                            <i class="icon-help-circle-outline f-22 text-general" />
                        </el-tooltip>
                    </div>
                    <p class="text-general f-600 f-20">
                        {{ parametros.cantidadIgnorar }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="parametros.idProveedor == 0 || parametros.idProveedor == -1" class="row mx-0 br-8 bg-white text-general px-2 py-3 mb-3">
            <p class="col-12 f-17 f-600 mb-3">
                Todos los proveedores
            </p>
            <div class="col-12 d-middle">
                <div class="col-auto pl-0">
                    <p class="f-14 text-center">
                        Valor de la compra
                    </p>
                    <p class="f-20 f-600 text-center">
                        {{ separadorNumeroDecimales(compras.infoProveedores.total_compra) }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="f-14 text-center">
                        No. de productos
                    </p>
                    <p class="f-20 f-600 text-left">
                        {{ compras.infoProveedores.total_productos }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="f-14 text-center">
                        No. de unidades
                    </p>
                    <p class="f-20 f-600 text-left">
                        {{ compras.infoProveedores.total_unidades }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="f-14 text-center">
                        Proveedores
                    </p>
                    <p class="f-20 f-600 text-left">
                        {{ compras.infoProveedores.total_proveedores }}
                    </p>
                </div>
            </div>
            <p class="col-12 f-600 f-18 mt-4">
                Compras por categorías
            </p>
            <div class="col-12 mt-2">
                <div class="row mx-0">
                    <div v-for="(data,index) in compras.dataCategorias.previsualizar" :key="index" class="bg-fondo text-general px-3 py-2 br-8 text-center mr-3">
                        <p class="f-600 f-16">{{ separadorNumeroDecimales(data.total_compra) }}</p>
                        <p class="f-14 mt-2">{{ data.categoria }}</p>
                    </div>
                    <div v-if="compras.dataCategorias.todas.length > 6" class="text-white bg-general3 px-4 br-8 py-3 f-14 cr-pointer" @click="categoriasComprar">
                        <p class="lh-16">Ver <br /> más </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="parametros.idProveedor != 0 && parametros.idProveedor != -1" class="row mx-0 bg-white br-8 text-general px-2 py-3">
            <p class="col-12 text-generalf-18 f-600">
                {{ compras.infoProveedores.nombre }}
            </p>
            <div class="col-12">
                <div class="row mx-0">
                    <div class="col d-flex">
                        <div class="col-auto pl-0">
                            <p class="f-14 text-center">
                                Valor de la compra
                            </p>
                            <p class="f-20 f-600 text-center">
                                {{ separadorNumeroDecimales(compras.infoProveedores.total_compra) }}
                            </p>
                        </div>
                        <div class="col-auto">
                            <p class="f-14 text-center">
                                No. de productos
                            </p>
                            <p class="f-20 f-600 text-left">
                                {{ compras.infoProveedores.total_productos }}
                            </p>
                        </div>
                        <div class="col-auto">
                            <p class="f-14 text-center">
                                No. de unidades
                            </p>
                            <p class="f-20 f-600 text-left">
                                {{ compras.infoProveedores.total_unidades }}
                            </p>
                        </div>
                    </div>
                    <div class="col d-flex">
                        <img :src="compras.infoProveedores.logo ? compras.infoProveedores.logo : '/img/no-imagen/proveedor.svg'" class="obj-cover br-8" width="102" height="102" />
                        <div class="col">
                            <div class="row mx-0 align-items-center">
                                <div class="col-auto px-0 f-15">
                                    Nit. {{ compras.infoProveedores.nit }}
                                </div>
                                <div class="mn-pill bg-green text-white px-2 ml-auto cr-pointer" @click="comentarios">
                                    <i class="icon-message-text-outline f-15" />
                                    <span class="ml-2">{{ compras.infoProveedores.total_comentarios }}</span>
                                </div>
                            </div>
                            <p class="f-14 mt-1">
                                Observación: {{ compras.infoProveedores.observaciones ? compras.infoProveedores.observaciones : 'No registra' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 my-3">
                <p class="f-18 f-600">
                    Compras por categorías
                </p>
                <div class="row mx-0 mt-1">
                    <div v-for="(data,index) in compras.dataCategorias.previsualizar" :key="index" class="bg-fondo text-general px-3 py-2 br-8 text-center mr-3">
                        <p class="f-600 f-16">{{ separadorNumeroDecimales(data.total_compra) }}</p>
                        <p class="f-14 mt-2">{{ data.categoria }}</p>
                    </div>
                    <div v-if="compras.dataCategorias.todas.length > 6" class="text-white bg-general3 px-4 br-8 py-3 f-14 cr-pointer" @click="categoriasComprar">
                        <p class="lh-16">Ver <br /> más </p>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex mt-4">
                <div class="col-auto pl-0">
                    <p class="f-14">
                        Última compra
                    </p>
                    <p class="f-20 f-600 text-center">
                        {{ separadorNumeroDecimales(compras.infoProveedores.ultima_compra_valor) }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="f-14 text-center">
                        No. de productos
                    </p>
                    <p class="f-20 f-600 text-left">
                        {{ compras.infoProveedores.ultima_compra_productos }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="f-14">
                        Fecha de compra
                    </p>
                    <p class="f-20 f-600 text-left">
                        {{ compras.infoProveedores.ultima_compra_fecha | helper-fecha('DD MMM YYYY') }}
                    </p>
                </div>
                <div class="col-1" />
                <div class="col-auto">
                    <p class="f-14 text-center">
                        Productos favoritos
                    </p>
                    <p class="f-20 f-600 text-left">
                        {{ compras.infoProveedores.productos_favoritos }}
                    </p>
                </div>
            </div>
            <p class="col-12 text-general f-600 f-17 mt-5">
                Compras últimas 24 semanas
            </p>
            <div class="col-12 d-flex">
                <div class="col px-0">
                    <echart :options="dataGrafica" />
                </div>
                <div class="col-auto pl-4 pt-5">
                    <div class="bg-fondo py-2 px-4 br-8 text-center">
                        <p class="text-general f-16 f-600">Frecuencia de compra</p>
                        <p class="text-general f-14 mt-2">
                            Cada {{ compras.infoProveedores.grafica.frecuencia }} Días
                        </p>
                    </div>
                    <div class="bg-fondo py-2 px-4 br-8 text-center mt-3">
                        <p class="text-general f-16 f-600">Promedio de compra</p>
                        <p class="text-general f-14 mt-2">
                            {{ separadorNumeroDecimales(compras.infoProveedores.grafica.promedio) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white br-8 mt-3 text-general px-2 py-3">
            <div class="col-12 px-0">
                <tabla-general :data="compras.productos" :columnas="dataColumns" class-header="text-general f-16" alto="auto" @filaFuncion="verProducto">
                    <template slot="cabecera-izquierda">
                        <div class="col px-0 d-middle">
                            <div class="col-auto text-general f-600">
                                Productos
                            </div>
                            <div v-if="compras.productos.length > 0" class="btn-general f-14 px-3 ml-auto">
                                <vue-excel-xlsx
                                class="my-lg-2 boton-excel"
                                :data="compras.productos"
                                :columns="columnsExport"
                                :filename="'productos'"
                                :file-type="'xlsx'"
                                :sheetname="'productos'"
                                >
                                    Exportar tabla de excel
                                </vue-excel-xlsx>
                            </div>
                        </div>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-categorias-comprar ref="categoriasComprar" />
        <modal-producto ref="modalProducto" @actualizar="actualizarProductos" />
        <modal-comentarios ref="modalComentarios" @comentario-nuevo="actualizarTotalComentario" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import echarts from 'echarts'
Vue.component('echarts',echarts);

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx)

export default {
    components: {
        modalCategoriasComprar: () => import('../partials/modalCategoriasComprar'),
        modalProducto: () => import('../partials/modalProducto'),
        modalComentarios: () => import('../partials/modalComentarios')
    },
    props: {
        compras: {
            type: Object,
            default: () => {}
        },
        parametros: {
            type: Object,
            default: () => {}
        },
    },
    data(){
        return {
            url_tienda: 'https://telemedellin.tv/wp-content/uploads/2021/09/pintar-fachadas-tienda-d1-pintuco-imgppal.jpg',
            dataGrafica: {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let bar = params[0]
                        return `${bar.axisValue} <br />
                        ${bar.marker} ${this.separadorNumeroDecimales(bar.value)} <br />
                        `
                    },
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    axisLine: {
                        lineStyle: {
                            color: '#F7F7F7'
                        },
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#F7F7F7'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    splitLine: {
                        lineStyle:{
                            color: '#F7F7F7'
                        }
                    }
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        itemStyle: {
                            color: '#DFE4E8'
                        },
                        barWidth: 20
                    }
                ]
            },
            dataColumns: [
                { valor: 'sku', titulo: 'SKU' },
                { valor: 'barcode', titulo: 'BarCode' },
                { valor: 'producto', titulo: 'Producto', ancho: 300 },
                { valor: 'presentacion', titulo: 'Presentacion' },
                { valor: 'proveedor_favorito', titulo: 'Proveedor favorito', ancho: 210 },
                { valor: 'cantidad_comprar', titulo: 'Cantidad a comprar', ancho: 210 },
                { valor: 'valor_unitario', titulo: 'Valor unitario' },
                { valor: 'valor_total', titulo: 'Valor total' },
                { valor: 'ultima_compra_fecha', titulo: 'Fecha últ. compra' },
                { valor: 'ultima_compra_proveedor', titulo: 'Proveedor de la compra', ancho: 280 }, //
                { valor: 'ultima_compra_cantidad', titulo: 'Cantidad últ.compra', ancho: 280 }, //
                { valor: 'ultimo_precio_valor', titulo: 'Precio de venta' },
                { valor: 'ultimo_precio_fecha_actualizada', titulo: 'Fecha últ.actualizacion de precio', ancho: 325 },
                { valor: 'rotacion_cedis', titulo: 'Rotación cedis' },
                { valor: 'rotacion_general', titulo: 'Rotación general' },
                { valor: 'cantidad_carrito', titulo: 'Cantidad en carrito', ancho: 210 },
                { valor: 'cantidad_proceso', titulo: 'Cantidad en pedido', ancho: 210 },
                { valor: 'cantidad_existente', titulo: 'Stock' },
                { valor: 'venta_sin_stock', titulo: 'Venta sin stock' }
            ],
            columnsExport: [
                {
                    label: "SKU",
                    field: "sku",
                },
                {
                    label: "Barcode",
                    field: "barcode",
                },
                {
                    label: "Producto",
                    field: "producto",
                },
                {
                    label: "Presentacion",
                    field: "presentacion",
                },
                {
                    label: "Proveedor favorito",
                    field: "proveedor_favorito",
                },
                {
                    label: "Cantidad a comprar",
                    field: "cantidad_comprar",
                },
                {
                    label: "Valor unitario",
                    field: "valor_unitario",
                },
                {
                    label: "Valor total",
                    field: "valor_total",
                },
                {
                    label: "Fecha últ. compra",
                    field: "ultima_compra_fecha",
                },
                {
                    label: "Proveedor de la compra",
                    field: "ultima_compra_proveedor",
                },
                {
                    label: "Cantidad últ.compra",
                    field: "ultima_compra_cantidad",
                },
                {
                    label: "Precio de venta",
                    field: "ultimo_precio_valor",
                },
                {
                    label: "Fecha últ.actualizacion de precio",
                    field: "ultimo_precio_fecha_actualizada",
                },
                {
                    label: "Rotación cedis",
                    field: "rotacion_cedis",
                },
                {
                    label: "Rotación general",
                    field: "rotacion_general",
                },
                {
                    label: "Cantidad en carrito",
                    field: "cantidad_carrito",
                },
                {
                    label: "Cantidad en pedido",
                    field: "cantidad_proceso",
                },
                {
                    label: "Stock",
                    field: "cantidad_existente",
                },
                {
                    label: "Venta sin stock",
                    field: "venta_sin_stock",
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            filtroProveedorAplicado: 'proveedores/proveedores/filtroProveedorAplicado',
        }),
    },
    mounted(){
        this.armarGrafica();
    },
    methods: {
        actualizarProductos(idProducto){
            if(this.filtroProveedorAplicado){
                let index = this.compras.productos.findIndex(element => element.id_producto === idProducto)
                if(index != -1){
                    this.compras.productos.splice(index, 1);
                }
            }
        },
        armarGrafica(){
            if(this.parametros.idProveedor != 0 && this.parametros.idProveedor != -1){
                let grafica = this.compras.infoProveedores.grafica.datos;
                let countGrafica = grafica.length;
                let series = [];
                let xAxis = [];
                for(let i = 0; i < countGrafica; i++){
                    let semana = moment(grafica[i].desde).format('D MMM YYYY')+" al "+moment(grafica[i].hasta).format('D MMM YYYY');
                    xAxis.push(semana);
                    series.push(grafica[i].total);
                }
                this.dataGrafica.series[0].data = series;
                this.dataGrafica.xAxis.data = xAxis;
            }
        },
        categoriasComprar(){
            this.$refs.categoriasComprar.toggle(this.compras.dataCategorias.todas);
        },
        verProducto(row){
            this.$refs.modalProducto.toggle(row);
        },
        comentarios(){
            this.$refs.modalComentarios.toggle(this.parametros.idProveedor);
        },
        generarInformeNuevo(){
            this.$emit('informeNuevo');
        },
        actualizarTotalComentario(){
            let total = this.compras.infoProveedores.total_comentarios;
            this.compras.infoProveedores.total_comentarios = total + 1;
        },
    }
}
</script>
<style lang="scss" scoped>
.boton-excel {
    border: 0px;
    background: transparent;
    color: #fff;
}
</style>
